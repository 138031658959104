const ProgressGrid = (props) => {

  const gray = "#D8D8D8";
  const active = "linear-gradient(180deg, #5DB256 0%, #3A86C0 100%)";
  const completed = "#5DB256";

  const grid = ([
    { id: 1, bgColor: active },
    { id: 2, bgColor: gray },
    { id: 3, bgColor: gray },
    { id: 4, bgColor: gray },
    { id: 5, bgColor: gray },
    { id: 6, bgColor: gray },
    { id: 7, bgColor: gray },
    { id: 8, bgColor: gray },
    { id: 9, bgColor: gray },
  ]);

  // console.log(props.progress);

  // we have to divide progress which is 100% in 9 parts 
  // 100/9 = 11.11
  // so we divide progress by 11.11 to get the the value in 0-9 range 
  //const gridProgress = Math.round(props?.progress / 11.11);
  const gridProgress = props.section;
  // console.log(Math.round(props.progress/11.11));
  // console.log("gridProgress", gridProgress);

  // assign color to each grid block based on progress 
  for (let i = 0; i < grid.length; i++) {
    if (grid[i].id === 1 && gridProgress === 0) {
      grid[i].bgColor = active;
    } else if (grid[i]?.id === gridProgress) {
      grid[i].bgColor = active;
    } else if (grid[i].id < gridProgress) {
      grid[i].bgColor = completed;
    } else {
      grid[i].bgColor = gray;
    }
  }

  // console.log(grid);

  return (
    <div className='progress-grid'>
      {grid.map(square => {
        return (<div key={`grid${square.id}`} style={{ background: square.bgColor, fontSize: "12px" }} className='grid-square'></div>)
      })}
    </div>
  )
}

export default ProgressGrid