import React, { useState } from 'react';

import LinearProgress from '@mui/material/LinearProgress';

import useWindowDimensions from '../Hooks/useWindowDimensions';

const ProgressBar = props => {

  const { windowWidth } = useWindowDimensions();

  let activeColor = '#0F4359';
  let passiveColor = '#343333';
  let sections = [];
  let numOfQuestions = props?.count;
  const currentProgress = props?.currentProgress;
  const currentSectionName = props?.currentSectionName;
  const questionsPerSection = props?.questionsPerSection;
  const maxProgressPerSec = questionsPerSection?.map((item, idx) => questionsPerSection.slice(0, idx + 1).reduce((a, b) => a + b));
  // min progress needed in first section is always 0 
  const minProgressPerSec = [0, ...maxProgressPerSec];
  // console.log("minProgress", minProgressPerSec);

  props?.sections?.forEach((section, idx) => {
    sections.push({
      name: section.section,
      style: { color: passiveColor, fontWeight: '400' },
      questions: questionsPerSection[idx],
      progress: 0, minProgress: minProgressPerSec[idx], maxProgress: maxProgressPerSec[idx]
    })
  })

  console.log("sections", sections);

  let progressWidth = (100 / sections.length) + '%';
  if (window.innerWidth < 650) {
    progressWidth = '100%';
  }

  sections?.forEach((sec, idx) => {
    if (currentProgress >= sec.minProgress && currentProgress + 1 <= sec.maxProgress) {
      sec.progress = (((currentProgress - sec.minProgress) + 1) / sec.questions) * 100;
      sec.style = { color: activeColor, fontWeight: '700' };
    } else {
      if ((currentProgress + 1) > (sec.maxProgress)) {
        sec.progress = 101;
      } else sec.progress = -1;
      sec.style = { color: passiveColor, fontWeight: '400' };
    }
  })

  // let progressPercentage = Math.round(((props?.currentProgress + 1) / numOfQuestions) * 100);

  // calculate percentage per section per question 
  const calcPercentPerQuestion = () => {
    let questionsPerSection = props?.questionsPerSection;
    let sections = props?.questionsPerSection?.length;
    // 100% divided by number of sections 
    let eachSection = parseFloat((100 / sections).toFixed(2));
    // console.log(eachSection);
    let progress = 0;
    let progressPerQuestionArr = [];
    for (let i = 0; i < sections; i++) {
      progress = eachSection / questionsPerSection[i];
      // console.log(progress);
      for (let j = 0; j < questionsPerSection[i]; j++) {
        progressPerQuestionArr.push(parseFloat(progress.toFixed(2)));
      }
    }

    let accumulator = 0
    progressPerQuestionArr?.forEach((value, index) => {
      if (index <= props?.currentProgress) { accumulator += value; }
      return accumulator;
    })
    // console.log("accumulator", accumulator);
    return Math.round(accumulator);
  }
  const progressPercent = calcPercentPerQuestion();
  console.log(progressPercent);


  return (
    <div>

      {windowWidth > 650 ? <>

        <p className="progress-percent-text" variant="determinate" style={{
          marginLeft: progressPercent == 5 ? `calc(${progressPercent}% - 1ch)` : progressPercent == 100 ? `calc(${progressPercent}% - 3ch)` : `calc(${progressPercent}% - 2ch)`, transition: "all 0.4s linear", fontSize: "21px", fontWeight: 700, lineHeight: "25px", color: "#0F4359", marginBottom: "6px", paddingTop: "10px", 
        }}>{`${progressPercent}%`}</p>

        <div className="progress-bar">
          {sections.map((sec, idx) => {
            return (
              <div key={idx} className="bar-section" style={{ width: progressWidth }}>
                {/* {(sec.progress > 0 && sec.progress <= 100)?
                <img style={{marginLeft: (sec.progress - 9) + '%', transition: '1s'}}decoding="async" height="30" src="https://md1.bleat.church/wp-content/uploads/2021/05/paper-boat.png" className="attachment-full size-full" alt="" loading="lazy" />
                : <img style={{marginLeft: (sec.progress - 9) + '%', opacity: 0}}decoding="async" height="30" src="https://md1.bleat.church/wp-content/uploads/2021/05/paper-boat.png" className="attachment-full size-full" alt="" loading="lazy" />
              } */}
                <div className="bar"><LinearProgress className="custom-bar" variant="determinate" value={sec.progress} /></div>
                <div className="section-title" style={sec.style}>{sec.name.toUpperCase()}</div>
              </div>
            )
          })}
        </div>
      </>
        : <>
          <div style={{ width: "90%", margin: "0 auto" }}>

            <p className="progress-percent-text" variant="determinate" style={{
              margin: "10px auto", marginLeft: progressPercent == 5 ? `calc(${progressPercent}% - 1ch)` : progressPercent == 100 ? `calc(${progressPercent}% - 3ch)` : `calc(${progressPercent}% - 1ch)`, transition: "all 0.4s linear", fontSize: "21px", fontWeight: 700, lineHeight: "25px", color: "#FAD41F", marginBottom: "5px",
              // width: "90%", maxWidth: "90%"
            }}>{`${progressPercent}%`}</p>

            <div className="progress-bar">

              <div className="bar-section" style={{ width: "100%", margin: "0 auto" }}>
                {/* {(sec.progress > 0 && sec.progress <= 100)?
          <img style={{marginLeft: (sec.progress - 9) + '%', transition: '1s'}}decoding="async" height="30" src="https://md1.bleat.church/wp-content/uploads/2021/05/paper-boat.png" className="attachment-full size-full" alt="" loading="lazy" />
          : <img style={{marginLeft: (sec.progress - 9) + '%', opacity: 0}}decoding="async" height="30" src="https://md1.bleat.church/wp-content/uploads/2021/05/paper-boat.png" className="attachment-full size-full" alt="" loading="lazy" />
        } */}
                <div className="bar">
                  <LinearProgress sx={{
                    height: "6px", borderRadius: "3px",
                    bgcolor: '#E9E9E9', // sets the background color
                    '& .MuiLinearProgress-bar': { bgcolor: '#FAD41F', }, // sets the color of the progress bar
                  }} className="custom-bar" variant="determinate" value={progressPercent} /></div>
                <div className="section-title" style={{ color: "#0F4359", margin: "10px auto", fontSize: "16px", fontWeight: 700, lineHeight: "18px", textAlign: "center" }}>{currentSectionName?.toUpperCase()}</div>
              </div>
            </div>
          </div>
        </>
      }

    </div>
  );
}
export default ProgressBar;



