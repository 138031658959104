import { Link, useParams } from "react-router-dom";
import Button from '@mui/material/Button';
import testimonialImage from '../Assets/Images/testimonial_m.png';

const Testimonial = () => {

  const { id } = useParams();

  return (
    <>
      <div className="testimonial-container">
        <div className="testimonial">

          {/* <div className="testi">
            <span className="quotation-mark">“</span>The team view is gold! Getting a line of sight into how every member of prospective teams think and being able to see it all on 1 dashboard has helped us a great deal. We’ve now been able to identify trends and personalize follow up for each prospect effectively.<span className="quotation-mark bottom"><div className="quotation-block">“</div></span>
          </div> */}
          <div className="testi">
            <div className="quotation-mark">“</div>
            <div className="testimonial-text">
            We hired Altezza to fill a gap on our team with the expectation of increasing the momentum we were already experiencing. We were masterfully matched with a sales pro who seemed to naturally share our values from day one.
            <br/><br/>Our sales reached new heights this past year and we are coaching more Leaders than ever before. If you are looking to pour gas on your momentum while also refining your systems for growth, I wholeheartedly recommend the Altezza team!
            </div>
            <div className="quotation-mark bottom">“</div>
          </div>

          <div className="avatar">
            <img src={testimonialImage} alt="pastor" />
          </div>

          <div className="designation">
          Shawn Lovejoy<br />
            <span>Founder, Courage to Lead</span>
          </div>
          {/* <div className="cta-btn">
          <Link to={"/invite-team/" + id} target="_blank">
          <Button>DISCOVER YOUR PRIORITY</Button>
          </Link>
        </div> */}

        </div>
      </div>
    </>
  );
}

export default Testimonial;
