import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import ProgressGrid from './ProgressGrid';

const ProgressBarSingle = props => {

  const totalNumOfQuestions = props?.totalNumOfQuestions;
  console.log(props)

  let percentageColor = '#202945';
  // let passiveColor          = '#343333';
  // let sectionstyle         = { color: activeColor, fontWeight: '700' };

  let progress = Math.round(((props?.currentProgress + 1) / totalNumOfQuestions * 100));

  return (
    <div>
      <div className="progress-bar" style={{justifyContent:"center", display:"flex" }} >
        <div className="bar-section" style={{ width: "73.875%" }}>
          <p className="progress-percentage" style={{ position:"relative", left:`${progress-2}%`,width:"36px",height:"22px",margin:"26px 0 5px 0",color: percentageColor }}><span style={{width: "36px", height:"22px", fontWeight:"700", fontSize: "18px",lineHeight: "22px"}}>{progress}%</span></p>
          <div className="bar"><LinearProgress sx={{background: '#E9E9E9' ,"& .MuiLinearProgress-barColorPrimary": {backgroundColor: "#F7D54E",}}} className="custom-bar" variant="determinate" value={parseInt(progress)} /></div>
          <ProgressGrid progress={progress} section={props.section} />
        </div>
      </div>

    </div>
  );
}
export default ProgressBarSingle;
