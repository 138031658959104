import { useState, useEffect, useContext } from "react";
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import TextField from '@mui/material/TextField';
import { Box, Stepper, Button, CircularProgress } from '@mui/material';

// import QuestionTemp from '../Components/QuestionsTemp';

import prevArrow from '../Assets/Images/prev-arrow.svg';
import nextArrow from '../Assets/Images/next-arrow.svg';
import nextArrowWhite from '../Assets/Images/next-arrow-white.svg';

import Question from '../Components/Questions';
import AdditionalQuestionContext from '../Store/AdditionalQuestionContext';
import '../App.scss';

/*let questions = [
  {
    id: 21,
    question: "How urgently do you want to ensure your church<br/>is equipped for future evangelism?",
    answer: 0,
    type: "slider"
  },
  {
    id: 22,
    question: "What's the biggest reason for your church to be prepared<br/>to reach out to the unchurched in your community?",
    answer: "",
    type: "text"
  },
  {
    id: 23,
    question: "What's the biggest hurdle that keeps your church from<br/>reaching out to more people?",
    answer: "",
    type: "text"
  }
]*/

const AssessmentAdditional = () => {

  let navigate      = useNavigate();
  const { id }      = useParams();
  const addCtx 	    = useContext(AdditionalQuestionContext);

  // let isBackFromDetailsPage = localStorage.getItem("backFromDetailsPage");
  useEffect(() => {
    // document.body.style.backgroundColor = "#E7E9EB";
    getAdditional();
  // }, [addCtx?.questions?.length, isBackFromDetailsPage]);
  }, [addCtx?.questions?.length]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [searchParams, setSearchParams]   = useSearchParams();
  const [isLoading, setIsLoading]         = useState(true);
  const [questions, setQuestions]         = useState([]);
  const [questionCount, setQuestionCount] = useState(0);
  const [activeStep, setActiveStep]       = useState(0);
  const [prevValue, setPrevValue]         = useState(0);
  const [textInput, setTextInput]         = useState("");
  const [nextArrowImg, setNextArrowImg]   = useState(nextArrow);

  
  // const [nextDisabled, setNextDisabled] = useState(true);

  // console.log("addCtx.questions", addCtx.questions);
  // console.log("localStorage.getItem(backFromDetailsPage)", localStorage.getItem("backFromDetailsPage"));
  // console.log("typeof localStorage.getItem(backFromDetailsPage)", typeof localStorage.getItem("backFromDetailsPage"));
  function getAdditional() {
    if(addCtx.questions.length > 0) {
      console.log("addCtx.questions", addCtx.questions);
      setQuestions(addCtx.questions);
      setQuestionCount(addCtx.questions.length);
      setIsLoading(false);

      // go to the last additional question if coming back from Details page 
      // typeof localStorage.getItem("backFromDetailsPage") is string so "true"
      if(localStorage.getItem("backFromDetailsPage") === "true") {
        setActiveStep(addCtx.questions.length - 1);
      } else {
        // go to the first unanswered question 
        for (let i = 0; i < addCtx.questions?.length; i++) {
          if(!addCtx.questions?.[i]?.answer && !addCtx.questions?.[i]?.answerTxt) {
            setActiveStep(i);
            break;
          } else setActiveStep(addCtx.questions.length - 1);
        }
      }

    } else {
        let clientId              = process.env.REACT_APP_CLIENT_ID;
      
        fetch(process.env.REACT_APP_API_URI + '/v1/questions/' + clientId + '/additional', {
        method: 'GET',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' }
      }).then(response => {
        return response.json();
      }).then(data => {
        if(data.status == "success") {
          let questions = data.questions;
          if(retrieveStoredAnswers()) {
            let answeredQuestions = [];
            let storedQuestions = retrieveStoredAnswers();
            questions.forEach(q => {
              let question = storedQuestions.find(obj => obj.id == q.id);
              if(question) {
                q.answerTxt = question.answerTxt;
                q.answer = question.answer;
                q.answers = question.answers;
              }
            })
          }
          setQuestions(questions);
          setQuestionCount(questions.length);
          addCtx.addQuestions(questions);
          setIsLoading(false);
          // setActiveStep(data.questions.length - 1);
          // setPrevValue(data.questions[data.questions.length - 1].answer);
        } else {
          // setOpenSnackbar(true);
        }
      });
    }
  }

  function completeAssessmentAdditional() {

    setIsLoading(true);

    fetch(process.env.REACT_APP_API_URI + '/v1/assessment/' + id + '/additional', {
			method: 'PUT',
			mode: 'cors',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				questions
			})
		}).then(response => {
			return response.json();
		}).then(data => {
			if(data.status == "success") {
        if(data.member == true) {
          if(searchParams.get("member") == data.memberId) {
            navigate("/details/" + id + "?" + searchParams, { replace: true });
          } else navigate("/details/" + id + '?member=' + data.memberId, { replace: true });
        } else {
          navigate("/details/" + id, { replace: true });
        }
        // localStorage.removeItem("assessmentAnswers");
        // localStorage.removeItem("additionalAnswers");
        // navigate("/results/" + id, { replace: true });
			} else {
				// setOpenSnackbar(true);
			}
		}).catch((err) => console.error(err));
	}

  const retrieveStoredAnswers = () => {
    if (typeof window !== 'undefined') {
      // Get the current localstorage
      let savedAnswers = JSON.parse(localStorage.getItem('additionalAnswers'));
      if(savedAnswers && savedAnswers.length != 0) {
        return savedAnswers;
      }
    }
    return;
  }

  const prevQuestion = () => {
    if(activeStep == 0) {
      navigate("/assessment/" + id + '/review', { replace: true });
      // navigate("/assessment", { replace: true });
    } else {
      setActiveStep(activeStep - 1);
      setPrevValue(questions[activeStep - 1].answer);
    }
  };

  const nextQuestion = () => {
    // console.log("activeStep", activeStep); 
    // console.log("questions.length - 1", questions.length - 1);
    if(activeStep < questions.length - 1) {
      addCtx.updateAnswer(questions[activeStep].id, questions[activeStep].answer);
      addCtx.updateTextAnswer(questions[activeStep].id, questions[activeStep].answerTxt);
      setTimeout(() => {
        setTextInput("");
        setActiveStep(activeStep + 1);
        setPrevValue(questions[activeStep + 1].answer);
      }, 20);
    } else {
      setTimeout(() => { completeAssessmentAdditional(); }, 500);
    }
  };

  const moveToNextQuestion = (answer) => {
    if(activeStep < questions.length - 1) {
      questions[activeStep].answer = answer;
      addCtx.updateAnswer(questions[activeStep].id, questions[activeStep].answer);
      addCtx.updateTextAnswer(questions[activeStep].id, questions[activeStep].answerTxt);
      console.log(questions[activeStep]);
      setTimeout(() => {
        setActiveStep(activeStep + 1);
      }, 500);
    } else {      
      setTimeout(() => { completeAssessmentAdditional(); }, 500);
    }
  }

  const updateTextQuestion = (answer) => {
    setTextInput(answer);
  }

  const nextDisabled = (answer) => {
    if(questions[activeStep].mandatory === "no") {
      return { display: "block"};
    } else {
      if(questions[activeStep].type === "slider" && questions[activeStep].answer !== 0) {
        return { display: "block" };
      } else if(questions[activeStep].type === "textarea" && questions[activeStep].answerTxt !== "") {
        return { display: "block" };
      }
    } 
    // return { display: "none"};
  }

  // const updateQuestion = (event, step) => {
  //   setTextField(event.target.value);
  //   questions[step].answer = event.target.value;
  //   // return true;
  // }
  
  const nextQuestionClass = () => {
    // if(activeStep === questions?.length - 1) return "nextbtn go-to-results"
    if(activeStep === questions?.length - 1) return "nextbtn all-set-btn"
    if(activeStep === 0) {
      return "nextbtn";
    } else if(activeStep === 7) {
      return "all-set-btn nextbtn";
    } else return "nextbtn";
  }

  const nextArrowFunc = () => {
    {/*if(activeStep == 0) {
      return nextArrow;
    }*/}
    return nextArrow;
  }

  const getStepContent = (step) => {
    return (
      <Question question={questions[step]} questionCount={questionCount} questionAnswered={moveToNextQuestion} textAnswer={updateTextQuestion} currentValue={prevValue}/>
    );
  };

  // const getStepContent = (step) => {
  //   if(step == 0) {
  //     return (
  //       <QuestionTemp question={questions[step].question} questionAnswered={moveToNextQuestion} currentValue={prevValue}/>
  //     );
  //   } else {
  //     return <div>
  //       <div className="question textarea">
  //         <p dangerouslySetInnerHTML={{__html: questions[step].question}}></p>
  //       </div>
  //       <div className="textarea-field">
  //         <TextField hiddenLabel multiline rows={4} placeholder="Please type your response here" value={textField}  onChange={(e) => {updateQuestion(e, step)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
  //       </div>
  //     </div>
  //   }
    
  // };

  const showNextButton = () => {
    if(activeStep === questions?.length - 1) {
      return <>
        {/* SHOW MY RESULTS */}
        You're all set!
      </>;
    } else if(activeStep === 1) {
      return <>
        Next&nbsp;&nbsp;<img src={nextArrowImg} alt="Next Button" />
      </>;
    } else {
      return <>
        NEXT&nbsp;&nbsp;<img src={nextArrowImg} alt="Next Button" />
      </>;
    }
  }


  if (isLoading) {
		return <div className="container assessment-additional loading"><CircularProgress style={{ color: "#FAD41F" }} size={60} /></div>
	}



  return (
    <div className="container assessment-additional">
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep}></Stepper>
    </Box>
    <div>
      <div className="steps-container">
        <div>
          <div className="steps">
            <div className="spacer"></div>

            <h1>PERSONAL PRIORITY</h1>

            {getStepContent(activeStep)}

            <div className="step-buttons">
              <Button onClick={() => {
                prevQuestion();
              }}>
                <img src={prevArrow} />&nbsp;&nbsp;PREV
              </Button>
              <Button 
                disabled={questions?.[activeStep]?.answerTxt?.length > 0 ? false : true} 
                className={nextQuestionClass()} 
                onClick={() => {
                  window.scrollTo(0, 0);  // Add this line
                  nextQuestion();
                }} 
                style={nextDisabled()}
                onMouseEnter={() => setNextArrowImg(nextArrowWhite)}
                onMouseLeave={() => setNextArrowImg(nextArrow)}
              >
                {showNextButton()}
              </Button>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}
export default AssessmentAdditional;