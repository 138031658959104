import React, { useRef, useState, useEffect } from 'react'
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import { Link, useParams } from "react-router-dom";
import RemoveIcon from '@mui/icons-material/Remove';
import Tabs from '../../Pages/Tabs';
import LinearProgress from '@mui/material/LinearProgress';
import UrgencyScore from './UrgencyScore';
import VideoThumbnail from '../../Assets/Images/video-thumbnail.png';
import PlayButton from '../../Assets/Images/play-button.png';
import downloadIcon from '../../Assets/Images/download-icon.svg';
import Video from '../Video';
import BarGraph from './BarGraph';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import useWindowDimensions from '../../Hooks/useWindowDimensions';
import ResultBarRainbow from '../Results/ResultBarRainbow';
import ResultSectionProgressBars from '../Results/ResultsSectionBarGraph';

const DashboardGroup = ({ dashboardGroupProps }) => {

  // const [videoSrc, setVideoSrc] = useState("https://40parables-assets.s3.amazonaws.com/Church-Fuel-Aligned.mp4");
  const { windowWidth } = useWindowDimensions();
  const [totalAlignment, setTotalAlignment] = useState(false);

  const [pdfClass, setPdfClass] = useState(false);

  const { hurdles, enablers, sections, questions, members, bottoms, tops, score, scores, toggleMore, setToggleMore, urgencyComponentProps } = dashboardGroupProps;

  console.log("dashboardGroupProps", dashboardGroupProps);

  useEffect(() => {

    sections.forEach(section => {
      if(section.totalUsers && section.totalUsers >= (Math.round(members.length / 2))) {
        setTotalAlignment(true);
      }
    });

  }, []);


  const alignment             = [];
  const nonAlignment          = [];
  let scoreAlign1             = [];
  let scoreAlign2             = [];
  let scoreAlign3             = [];
  let scoreAlign4             = [];
  let scoreAlign5             = [];
  let scoreAlign6             = [];
  let scoreAlign7             = [];
  let scoreAlign8             = [];
  let scoreAlign9             = [];

  members.forEach(member => {
    scoreAlign1.push(member.subScore1);
    scoreAlign2.push(member.subScore2);
    scoreAlign3.push(member.subScore3);
    scoreAlign4.push(member.subScore4);
    scoreAlign5.push(member.subScore5);
    scoreAlign6.push(member.subScore6);
    scoreAlign7.push(member.subScore7);
    scoreAlign8.push(member.subScore8);
    scoreAlign9.push(member.subScore9);
  });

  if(Math.max(...scoreAlign1) - Math.min(...scoreAlign1) > 20) {
    nonAlignment.push(sections[0].section);
  } else {
    alignment.push(sections[0].section);
  }
  if(Math.max(...scoreAlign2) - Math.min(...scoreAlign2) > 20) {
    nonAlignment.push(sections[1].section);
  } else {
    alignment.push(sections[1].section);
  }
  if(Math.max(...scoreAlign3) - Math.min(...scoreAlign3) > 20) {
    nonAlignment.push(sections[2].section);
  } else {
    alignment.push(sections[2].section);
  }
  if(Math.max(...scoreAlign4) - Math.min(...scoreAlign4) > 20) {
    nonAlignment.push(sections[3].section);
  } else {
    alignment.push(sections[3].section);
  }
  if(Math.max(...scoreAlign5) - Math.min(...scoreAlign5) > 20) {
    nonAlignment.push(sections[4]?.section);
  } else {
    alignment.push(sections[4]?.section);
  }
  if(Math.max(...scoreAlign6) - Math.min(...scoreAlign6) > 20) {
    nonAlignment.push(sections[5]?.section);
  } else {
    alignment.push(sections[5]?.section);
  }
  if(Math.max(...scoreAlign7) - Math.min(...scoreAlign7) > 20) {
    nonAlignment.push(sections[6]?.section);
  } else {
    alignment.push(sections[6]?.section);
  }
  if(Math.max(...scoreAlign8) - Math.min(...scoreAlign8) > 20) {
    nonAlignment.push(sections[7]?.section);
  } else {
    alignment.push(sections[7]?.section);
  }
  if(Math.max(...scoreAlign9) - Math.min(...scoreAlign9) > 20) {
    nonAlignment.push(sections[8]?.section);
  } else {
    alignment.push(sections[8]?.section);
  }
  console.log(alignment);
  console.log(nonAlignment);

  const toggleDetails = (id) => {
    if (id === toggleMore) {
      setToggleMore(0);
    } else {
      setToggleMore(id);
    }
  }

  const toggleMoreDisplay = (id) => {
    if (id === toggleMore) {
      return { display: "block" }
    } else {
      return { display: "none" }
    }
  }

  const toggleMoreIcon = (id) => {
    if (id === toggleMore) {
      return <RemoveIcon />
    } else {
      return <AddIcon />
    }
  }
  // const showTitleText = () => {
  //   if (score > 70) {
  //     return <h1>Your church exceeds expectations</h1>
  //   } else if (score > 45) {
  //     return <h1>Your church meets expectations</h1>
  //   } else {
  //     return <h1>Your church needs improvement</h1>
  //   }
  // }
  
  const sectionsAndSubScores = sections?.map((sec, idx) => ({ section: sec.section, score: scores[idx] }));

  console.log("sectionsAndSubScores", sectionsAndSubScores);
  
  const sortedSectionsAndSubScores = [...sectionsAndSubScores]?.sort((a, b) => a.score - b.score);
  console.log("sortedSectionsAndSubScores", sortedSectionsAndSubScores);

  const showScoreText = () => {
    if (score >= 0 && score <= 50) {
      return <div className="result-text">
        <p style={{ margin: "5px auto 15px" }}>Based on your score, it looks like your current sales strategy is not where you need it to be but here's the good news: Some incremental improvements can make a big difference.</p>
        <p style={{ margin: "20px auto 20px" }}>By focusing on each of the 4 critical areas in this assessment, you can begin to strengthen your sales approach and align around a plan to take your prospects from curious to closed!</p>
        <p style={{ margin: "15px auto 5px" }}>We would be happy to review your results with you and let you know what gaps you should work on closing first.</p>
      </div>
    } else if (score > 50 && score <= 80) {
      return <div className="result-text">
        <p style={{ margin: "5px auto 15px" }}>Based on your score, it looks like you have built a strong foundation for sales growth.</p>
        <p style={{ margin: "20px auto 20px" }}>With a few tweaks and some strong sales talent, you could easily see your business begin to scale at a whole new level!</p>
        <p style={{ margin: "15px auto 5px" }}>We would be happy to review your results with you and help you determine where you can grow even stronger and achieve new heights of sales success!</p>
      </div>
    } else if (score > 80) {
      return <div className="result-text">
        <p style={{ margin: "5px auto 15px" }}>Congratulations! Your score indicates that you clearly know what you're doing. We would guess that your business is growing steadily and on the way to great things!</p>
        <p style={{ margin: "20px auto 20px" }}>If that feels right to you but you're still looking for ways to accelerate growth even more, let's talk. The right adjustments or a killer sales pro might really get things cooking!</p>
        <p style={{ margin: "15px auto 5px" }}>Remember to revisit your strategy for each of the 4 areas of this assessment periodically to ensure it stays that way. This will help you and your team remain aligned as you work toward defining your sales goals and growing your impact!</p>
      </div>
    }
  };

  const getVideoSrc = () => {
    if(totalAlignment) {
      return "https://40parables-assets.s3.amazonaws.com/Church-Fuel-Aligned.mp4";
    }
    return "https://40parables-assets.s3.amazonaws.com/Church-Fuel-Not-Aligned.mp4";
  }

  const getLearnMoreUrl = () => {
    // check whether aligned or not and provide different link 
    // if(totalAlignment) {
    //   return "https://churchfuel.com/aligned";
    // }
    // return "https://churchfuel.com/notaligned";
    return "https://churchfuel.com/join/";
  }
  // console.log(totalAlignment);

  const alignmentRelatedTitle = () => {
    if(totalAlignment) {
      return <h2 className="dashboard-video-header">It looks like your team is aligned</h2>
    } else return <h2 className="dashboard-video-header">It looks like your team might not be aligned</h2>
  }


  const exportPDF = () => {
    
    document.querySelector('meta[name=viewport]').setAttribute("content", "width=1250");
    document.querySelector("#root").classList.add("pdf-export-dashboard");
    document.querySelector("div.App").setAttribute("id", "pdf-conversion");

    setPdfClass(true);

    // console.log("added print classes");
      
    html2canvas((document.querySelector("#root")), { scale: 3 }).then(canvas => {
      // if you want see your screenshot in body.
      // document.body.appendChild(canvas);  
      // const imgData = canvas.toDataURL('image/svg');
      // let imgWidth = 210; 
      // // let pageHeight = 295; 
      // let pageHeight = 297; 
      // let imgHeight = canvas.height * imgWidth / canvas.width;
      // let heightLeft = imgHeight;
      
      // let doc = new jsPDF('p', 'mm');
      // let position = 0;
      
      // doc.addImage(imgData, 'SVG', 0, position, imgWidth, imgHeight, undefined, 'FAST');
      
      // to add page to the PDF if content is more than 1 page 
      // heightLeft -= pageHeight;
      
      // while (heightLeft >= 0) {
      //   position = heightLeft - imgHeight;
      //   // console.log("position", position);
      //   // console.log(heightLeft, imgHeight);
      //   if (position < 80) { console.log(heightLeft, imgHeight); }
      //   doc.addPage();
      //   doc.addImage(imgData, 'SVG', 0, position, imgWidth, imgHeight, undefined, 'FAST');
      //   heightLeft -= pageHeight;
      // }



      const imgData = canvas.toDataURL('image/svg');
      // const pdf = new jsPDF("p", "mm", "a4");
      const pdf = new jsPDF({ orientation: "p", unit: "px", format: "a4"});
      const imgProperties = pdf.getImageProperties(imgData);
      
      let pageWidth = pdf.internal.pageSize.getWidth();
      // let pageHeight = (imgProperties.height * pageWidth) / imgProperties.width;
      let pageHeight = pdf.internal.pageSize.getHeight();
      console.log(pageWidth, pageHeight);
      const widthRatio = pageWidth / canvas.width;
      // const heightRatio = pageHeight / canvas.height;
      const heightRatio = ((imgProperties.height * pageWidth) / imgProperties.width) / canvas.height;
      const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

      const canvasWidth = canvas.width * ratio;
      const canvasHeight = canvas.height * ratio;
      console.log(canvasWidth, canvasHeight);
      console.log(canvas);
      
      const marginX = (pageWidth - canvasWidth) / 2;
      const marginY = (pageHeight - canvasHeight) / 2;
      
      pdf.addImage(imgData, 'SVG', 0, 0, pageWidth, pageHeight, undefined, 'FAST');
      pdf.save("Team Dashboard.pdf");
    });

    document.querySelector("div.App").removeAttribute("id");
    document.querySelector("#root").classList.remove("pdf-export-dashboard");
    document.querySelector('meta[name=viewport]').setAttribute("content", "width=device-width");
    setPdfClass(false);
    
  }

  const teamAnswers = [];

  members?.forEach((member) => {
		let question = member.answers?.find(a => a.question_id == 589);
		if (question?.answer_txt) teamAnswers.push(question?.answer_txt);
	})
	console.log("teamAnswers", teamAnswers);

  const showTitleText = () => {
    if(score >= 0 && score <= 50) return <h1>Well, this is difficult to say but it looks like you need some help … and fast!</h1>
    else if(score > 50 && score <= 80) return <h1>It looks like your current sales processes have a strong foundation for growth.</h1>
    else if(score > 80) return <h1>It looks like your current sales processes are scaling well and are set up for success.</h1>
  }

  //remove html tags
  function removeTags(str) {
		if ((str === null) || (str === ''))
			return false;
		else
			str = str.toString();
	
		return str.replace(/<[^>]+>/ig, '');
	}

  const showSchedule = () => {
    // if(isMember) {
    //   return null;
    // } else {}
    return <>
      {/* <hr style={{ width: "95%", maxWidth: "1000px", borderBottom: "1px solid #00000000", margin: "45px auto 45px" }} /> */}
      <div className="schedule-container" style={{ margin: "75px auto 15px" }}>
        <h3 style={{ color: "#F7D54E", textTransform: windowWidth > 650 ? "unset" : "uppercase" }}>Talk to us</h3>
        <p>Let's talk about your results and see how you can effectively align entire church teams around your solution.</p>
        <div className="call-link">
          <Button><a href="https://calendly.com/kurianbk/bleat-quick-call" target="_blank" rel="noreferrer">Book a Call</a></Button>
        </div>
      </div>
    </>
  }

  
  return (
    <>
      <div className="pdf-btn">
        <Button id="pdf-convert-btn" 
          onClick={exportPDF}> <img src={downloadIcon} className="download-icon" alt="download pdf" height="24px" width="24px" />&nbsp; Download Results</Button>
      </div>
      <div className="container dashboard">
        {/* <BarGraph showInvite={false} subscores={scores} sections={sections} pdfClass={pdfClass} /> */}
          {showTitleText()}
          <ResultBarRainbow score={score}/>
          {showScoreText()}

          <div className="score-section-container">

              {sectionsAndSubScores?.map(sec => {
                return (
                <div key={sec.section} className="score-section">
                  <p className="score-txt">{sec.score}%</p>
                  <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={sec?.score} /></div>
                  <p className="score-name">{sec.section}</p>
                </div>)
              })}

          </div>
          
        {windowWidth < 551 && <h3 style={{ textAlign: "center", margin: "5px auto 15px", fontSize: "18px", color: "#27282A" }}>INDIVIDUAL SCORES</h3>}

        <div className="mobile_scores">
          {/* <h2>INDIVIDUAL SCORES</h2> */}
          <div className="individual">

          {
            members.map((data, index) => (
              <>
                <div key={index} className="row" onClick={() => toggleDetails(data.id)}>
                  <div className="number">{ index + 1 }</div>
                  <div className="name">{ data.fullName }</div>
                  <div className="percent">{ data.score }%</div>
                  <div className="toggle">{ toggleMoreIcon(data.id) }</div>
                </div>
                <div className="scores" style={ toggleMoreDisplay(data.id) }>
                  <div className="section_rows">
                    <div className="section">Strategy</div>
                    <div className="section_score">{ data.subScore1 }%</div>
                  </div>
                  <div className="section_rows">
                    <div className="section">Communication</div>
                    <div className="section_score">{ data.subScore2 }%</div>
                  </div>
                  <div className="section_rows">
                    <div className="section">Marketing</div>
                    <div className="section_score">{ data.subScore3 }%</div>
                  </div>
                  <div className="section_rows">
                    <div className="section">Sales</div>
                    <div className="section_score">{ data.subScore4 }%</div>
                  </div>
                </div>
              </>
            )
          )}

          </div>
          
        </div>
        {/* <hr /> */}
        <table>
          <thead>
            <tr className="header">
              <th className="hash">#</th>
              <th>Name</th>
              {
                sectionsAndSubScores.map((data, index) => (
                  <th key={index} className="center-cell">{ data?.section?.toLowerCase() } (%)</th>
                  )
                  )}
              <th className="avg">Avg (%)</th>
            </tr>
          </thead>
          <tbody>

          {
            members.map((data, index) => (
              <tr key={index}>
                <td>{ index + 1 }</td>
                <td className="medium">{data.fullName}</td>
                {
                  sectionsAndSubScores.map((section, index) => {
                    let subScore;
                    if(index == 0) { subScore = data.subScore1; }
                    else if(index == 1) { subScore = data.subScore2; }
                    else if(index == 2) { subScore = data.subScore3; }
                    else if(index == 3) { subScore = data.subScore4; }
                    else if(index == 4) { subScore = data.subScore5; }
                    else if(index == 5) { subScore = data.subScore6; }
                    else if(index == 6) { subScore = data.subScore7; }
                    else if(index == 7) { subScore = data.subScore8; }
                    return <td key={index} className="center-cell">{subScore}</td>
                  }
                  )}
                <td className="center-cell">{data.score}</td>
              </tr>
            )
          )}

          <tr className='footer'>
            <th className="center-cell">&nbsp;</th>
            <th className="church-average">Team Average</th>
            {
              sectionsAndSubScores.map((data, index) => (
                <th key={index} className="center-cell">{scores[index]}</th>
                )
                )}
            <th className="center-cell">{score}%</th>
          </tr>
          </tbody>
        </table>  
                <hr/>
        <h2 style={{ textAlign: "center", fontSize: windowWidth > 650 ? "24px" : "18px", margin: windowWidth > 650 ? "30px auto 30px" : "45px auto 15px" }}>DETAILED REPORT</h2>
										
        {tops?.length > 0 && <div className="most-answered">
          <p className="green">Celebrate what you're doing well</p>
          {
            tops.map((data, index) => (
            // bottoms.map((data, index) => (
              <div key={index} className="most-answered-detail">{index + 1}. { removeTags(data.question) }</div>
            )
          )}
        </div>}

        {bottoms?.length > 0 && <div className="most-answered">
          <p className="red">Focus on what needs immediate attention</p>
          {
            bottoms.map((data, index) => (
            // tops.map((data, index) => (
              <div key={index} className="most-answered-detail">{index + 1}. { removeTags(data.question) }</div>
            )
          )}
        </div>}

        <div className="details-container">
          {/* <h2 style={{ textAlign: "center" }}>AREAS OF ALIGNMENT</h2> */}
          


          {/* {showTitleText()} */}

          {/* <div className="progress-bar">
          <div className="si_ass_result">
          <div className="si_ass_progress">
          <div className="progress-line" style={{ left: score + '%' }}></div>
          <span className="msi_score" style={{ left: score + '%' }}>{score}%</span>
          </div>
          <div className="pervalue">
          <h4 className="nimp" style={{ minWidth: '45%' }}></h4>
              <h4 className="mexpe" style={{ minWidth: '25.5%' }}></h4>
              <h4 className="eexpe" style={{ minWidth: '29%' }}></h4>
            </div>
            <div className="si_pro_header">
            <h4 className="nimp" style={{ minWidth: '45%' }}>Needs<br />improvement</h4>
            <h4 className="mexpe" style={{ minWidth: '25.5%' }}>Meets<br />expectations</h4>
            <h4 className="eexpe" style={{ minWidth: '29%' }}>Exceeds<br />expectations</h4>
            </div>
            </div>
            </div>
            
            {showScoreText()}
            
            <div className="result-sections">
            
            {
              sections.map((data, index) => (
                <div key={index} className="score-section">
                <div className="score-txt">{scores[index]}%</div>
                <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={scores[index]} /></div>
                {data.section}
                </div>
                ))
              } */}

          {/* <div className="score-section">
                    FEEDBACK<br/><br/>
                    <div className="score-txt">{subScore2}%</div>
                    <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={subScore2} /></div>
                    </div>
                    
                    <div className="score-section">
                    SERMON<br/><br/>
                    <div className="score-txt">{subScore3}%</div>
                    <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={subScore3} /></div>
                    </div>
                    
                    <div className="score-section">
                    COMMUNITY<br/><br/>
                    <div className="score-txt">{subScore4}%</div>
                    <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={subScore4} /></div>
                  </div> */}

          {/* </div> */}

          {/* <div>

<div className="mobile_scores">
            <h2>INDIVIDUAL SCORES</h2>
            <div className="individual">
            
            {
              members.map((data, index) => (
                <React.Fragment key={index}>
                <div className="row">
                <div className="number">{index + 1}</div>
                <div className="name">{data.fullName}</div>
                <div className="percent">{data.score}%</div>
                <div className="toggle" onClick={() => toggleDetails(data.id)}>{toggleMoreIcon(data.id)}</div>
                </div>
                <div className="scores" style={toggleMoreDisplay(data.id)}>
                <div className="section_rows">
                <div className="section">Beliefs</div>
                <div className="section_score">{data.subScore1}%</div>
                </div>
                <div className="section_rows">
                <div className="section">Strategy</div>
                <div className="section_score">{data.subScore2}%</div>
                </div>
                <div className="section_rows">
                <div className="section">Relevance</div>
                <div className="section_score">{data.subScore3}%</div>
                </div>
                <div className="section_rows">
                <div className="section">Process</div>
                <div className="section_score">{data.subScore4}%</div>
                </div>
                </div>
                  </React.Fragment>
                  )
                  )}
                  
                  </div>
                  
                  </div>
                  
                  <table>
                  <thead>
                  <tr className="header">
                  <th className="hash">#</th>
                  <th>Name</th>
                  {
                    sections.map((data, index) => (
                    <th key={index} className="center-cell">{data.section}</th>
                    )
                    )}
                    <th className="avg">Avg (%)</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                      members.map((data, index) => (
                        <tr key={index}>
                    <td>{index + 1}</td>
                    <td className="medium">{data.fullName}</td>
                    {
                      sections.map((section, index) => {
                        let subScore;
                        if (index === 0) { subScore = data.subScore1; }
                        else if (index === 1) { subScore = data.subScore2; }
                        else if (index === 2) { subScore = data.subScore3; }
                        else if (index === 3) { subScore = data.subScore4; }
                        else if (index === 4) { subScore = data.subScore5; }
                        else if (index === 5) { subScore = data.subScore6; }
                        else if (index === 6) { subScore = data.subScore7; }
                        else if (index === 7) { subScore = data.subScore8; }
                        return <td key={index} className="center-cell">{subScore}</td>
                      }
                      )}
                    <td className="center-cell">{data.score}%</td>
                  </tr>
                )
                )}

                <tr className='footer'>
                <th className="center-cell">&nbsp;</th>
                <th className="church-average">Team Average</th>
                {
                  sections.map((data, index) => (
                    <th key={index} className="center-cell">{scores[index]}</th>
                    )
                    )}
                    <th className="center-cell">{score}%</th>
                    </tr>
                    </tbody>
                    </table>
                  </div> */}

          {/* <hr /> */}

          {/* <h2>Detailed Report:</h2> */}
          
          <h2 className="answers-header">AREAS OF ALIGNMENT</h2>
          {/* <div className="answers-container">
            <div className="most-answered">
              <p className="green">Celebrate current areas of great alignment</p>
              {
                alignment.map((data, index) => {
                  return data && <div key={index} className="most-answered-detail">{index + 1}. {data.toLowerCase()}</div>
                }
                )}
            </div>

            <div className="most-answered">
              <p className="red">Analyze potential areas for better alignment</p>
              {
                nonAlignment.map((data, index) => (
                  <div key={index} className="most-answered-detail">{index + 1}. {data.toLowerCase()}</div>
                )
                )}
            </div>
          </div> */}
          
          {/* <div className="answers-container">
            <div className="most-answered">
              <p className="green">Celebrate current areas of great alignment</p>
              {
                alignment.map((data, index) => {
                  return data && <div key={index} className="most-answered-detail">{index + 1}. {data.toLowerCase()}</div>
                }
                )}
            </div>

            <div className="most-answered">
              <p className="red">Analyze potential areas for better alignment</p>
              {
                nonAlignment.map((data, index) => (
                  <div key={index} className="most-answered-detail">{index + 1}. {data.toLowerCase()}</div>
                )
                )}
            </div>
          </div> */}

          {/* {alignmentRelatedTitle()}
          <p className="dashboard-video-title">Watch the video to learn what your church's next step should be</p>

          <div className="dashboard-video">
            <Video vidsrcURL={getVideoSrc()} thumbnail={VideoThumbnail} playButton={PlayButton} />
          </div>

          <p className="dashboard-video-subtext">Ready for your church to take the next step toward greater alignment on its opportunities for ministry?</p> */}

          {/* <div className="cta-btn-learn">
            <Link to={"/invite-team/" + id} target="_blank">
            <a href={getLearnMoreUrl()} target="_blank" rel="noreferrer">
              <Button>LEARN MORE</Button>
            </a>
          </div> */}

          <div className="alignment">
            <Tabs members={members} questions={questions} sections={sections} />
          </div>


          {/* <UrgencyScore {...{ urgencyComponentProps }} /> */}


          {/* <div className="hurdles-enablers">
            <h2 style={{ color: '#202945' }}>HURDLES</h2>
            <div className="hurdles">
              <ul>
                {
                  enablers.map((data, index) => (
                    data.answer != null ? <li key={index}>{data.answer}</li> : ""
                  )
                  )}
              </ul>
            </div>
          </div> */}

          <div className="hurdles-enablers">
            <h2 style={{ color: '#202945', margin: "45px auto 15px", fontSize: windowWidth > 650 ? "21px" : "18px", textAlign: windowWidth > 550 ? "left" : "center" }}>1 THING THE ALTEZZA TEAM CAN HELP YOU WITH</h2>
            <div className="enablers">
              <ul>
              {
                  // all team members answers for additional question (id 426) 
                  teamAnswers.map((data, index) => (
                    data != null ? <li key={index}>{data}</li> : ""
                  )
                  )}
              </ul>
            </div>
          </div>
          
          {/* <div className="hurdles-enablers" style={{ margin: windowWidth > 650 ? "45px auto 15px" : "20px auto 15px" }}>
            <h2 style={{ color: '#202945' }}>HURDLES</h2>
            <div className="hurdles">
              <ul>
                {
                  // all team members answers for additional question (id 426) 
                  teamAnswers.map((data, index) => (
                    data != null ? <li key={index}>{data}</li> : ""
                  )
                  )}
              </ul>
            </div>
          </div>

          <div className="hurdles-enablers" style={{ margin: windowWidth > 650 ? "45px auto 15px" : "20px auto 15px" }}>
            <h2 style={{ color: '#202945' }}>ENABLERS</h2>
            <div className="enablers">
              <ul>
                {
                  // all team members answers for additional question (id 426) 
                  teamAnswers.map((data, index) => (
                    data != null ? <li key={index}>{data}</li> : ""
                  )
                  )}
              </ul>
            </div>
          </div> */}

          {/* <div style={{ height: "40px" }}></div> */}

        </div>


      </div>
      {/* {showSchedule()} */}
    </>
  )
}

export default DashboardGroup;
