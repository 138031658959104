import { useState, useEffect, useRef } from "react";
import { Link, useSearchParams } from "react-router-dom";

import VideoThumbnail from '../Assets/Images/video-thumbnail.png';
import VideoThumbnailDesktop from '../Assets/Images/video-thumbnail-desktop.png';
// import PlayBtn from '../Assets/Images/play-button.png';
import PlayBtn from '../Assets/Images/playbtn.png';
import Clock from '../Assets/Images/clock.png';
import Logo from '../Assets/Images/logo_EL.png';
import { Close } from "@mui/icons-material";
import '../App.scss';
import Video from '../Components/Video';
import useWindowDimensions from '../Hooks/useWindowDimensions';
import playbuttoniconhover from '../Assets/Images/playbuttoniconhover.svg';
import playbuttoniconyellow from '../Assets/Images/playbuttoniconyellow.svg';

const Start = props => {
  const { windowWidth }                   = useWindowDimensions();

  const [isLoading, setIsLoading]         = useState(true);
  const [videoThumbnailImg, setVideoThumbnailImg] = useState(VideoThumbnailDesktop);

  const [searchParams, setSearchParams]   = useSearchParams();
  const [playingVideo, setPlayingVideo]   = useState(false);
  const [startPlaying, setStartPlaying]   = useState(false);
  const [isHovered, setIsHovered]         = useState(false);    
  const vidRef                            = useRef(null);
  
  searchParams.get("leader");

  // useEffect(() => {
  //   if(windowWidth <= 830) setVideoThumbnailImg(VideoThumbnail);
  //   else setVideoThumbnailImg(VideoThumbnailDesktop);
  // }, [windowWidth]);


  const howItWorksLink = () => {
    if(searchParams == "") {
      return "/how-it-works";
    } else {
      return "/how-it-works?" + searchParams;
    }
  }


  const assessmentLink = () => {
    if(searchParams == "") {
      return "/assessment";
    } else {
      return "/assessment?" + searchParams;
    }
  }

  const playVideo = (e) => {
    // console.log(vidRef.current);
    // vidRef.current.play();
    // setPlayingVideo(true);
    // if(!startPlaying) {
    //   setStartPlaying(true);
    // }
    if(windowWidth > 830) {
      // document.getElementsByClassName("intro")[0].style.opacity = "0";
      document.getElementsByClassName("intro")[0].style.width = "0%";
      document.getElementsByClassName("intro")[0].style.padding = "0";
      document.getElementsByClassName("intro")[0].style.overflow = "hidden";
      document.getElementsByClassName("video")[0].style.width = "100%";
      document.getElementsByClassName("video")[0].style.margin = "0 auto";
      document.getElementsByClassName("video")[0].style.backgroundColor = "#0F4359";
      document.getElementsByClassName("video")[0].style.borderRadius = "0";
    } else {
      // document.getElementsByClassName("video")[0].style.minHeight = "auto";
    }
    vidRef.current.play();
    setPlayingVideo(true);
    if(!startPlaying) {
      setStartPlaying(true);
    }
  }

  const pauseVideo = (e) => {
    // console.log(vidRef.current);
    // vidRef.current.pause();
    // setPlayingVideo(false);
    vidRef.current.pause();
    if(windowWidth > 830) {
      document.getElementsByClassName("intro")[0].style.width = "45%";
      document.getElementsByClassName("intro")[0].style.padding = "0 2.5%";
      document.getElementsByClassName("intro")[0].style.overflow = "hidden";
      // document.getElementsByClassName("intro")[0].style.opacity = "1";
      document.getElementsByClassName("video")[0].style.width = "50%";
      document.getElementsByClassName("video")[0].style.margin = "0 auto";
      document.getElementsByClassName("video")[0].style.backgroundColor = "#033D36";
      document.getElementsByClassName("video")[0].style.borderTopLeftRadius = "10px";
      document.getElementsByClassName("video")[0].style.borderBottomLeftRadius = "10px";
    } else {
      // document.getElementsByClassName("video")[0].style.minHeight = "70vw";
    }
    setPlayingVideo(false);
  }

  const handleVideoPlaybackEnd = () => {
    setPlayingVideo(false);
    setStartPlaying(false);
    if(windowWidth > 830) {
      document.getElementsByClassName("intro")[0].style.width = "45%";
      document.getElementsByClassName("intro")[0].style.padding = "0 2.5%";
      document.getElementsByClassName("intro")[0].style.overflow = "hidden";
      // document.getElementsByClassName("intro")[0].style.opacity = "1";
      document.getElementsByClassName("video")[0].style.width = "50%";
      document.getElementsByClassName("video")[0].style.margin = "0 auto";
      document.getElementsByClassName("video")[0].style.backgroundColor = "#033D36";
      document.getElementsByClassName("video")[0].style.borderTopLeftRadius = "10px";
      document.getElementsByClassName("video")[0].style.borderBottomLeftRadius = "10px";
    } else {
      // document.getElementsByClassName("video")[0].style.minHeight = "70vw";
    }
    showThumbnail();
    showPlayButton();
  }

  const showThumbnail = () => {
    if(playingVideo) {
      return <img alt="thumbnail" className="thumbnail" src={videoThumbnailImg} style={{ opacity: '0' }} 
      onClick={(e) => pauseVideo(e)} />;
    }
    // if you want to see the thumbnail image when paused instead of video 
    // else return <img alt="thumbnail" className="thumbnail" src={videoThumbnailImg} />
    else {
      if(startPlaying) {
        return null
      } else {
        return <img alt="thumbnail" className="thumbnail" src={videoThumbnailImg} />
      }
    }
  }

  const showPlayButton = () => {
    if(playingVideo) {
      return null
    } else {
      // return <img className="playbtn" src={PlayBtn} onClick={(e) => playVideo(e)} alt="play button icon" />
      return <div className="playbtn" onClick={(e) => playVideo(e)} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} >
        <img className="playimg" src={isHovered ? playbuttoniconhover:playbuttoniconyellow } alt="play button icon" />
        <p className="playtext">WATCH VIDEO</p>
      </div>
      
    }
  }

  const showDetails = () => {
    return (
      <div className="owner-details">
        <h3 className="name">Kurian Babykutty</h3>
        <div className="position">CEO</div>
        <div className="company">40 Parables</div>
        <h3 className="name-mobile">Kurian Babykutty</h3>
        <span className="position-mobile">CEO, <span className="company-mobile">40 Parables</span></span>
      </div>
    )
  }

  const vidsrcURL = "https://40parables-assets.s3.amazonaws.com/Bleat-Strategic-Sales-Intro.mp4";

  return (
    <>
      <div className="container start">
				<div className="video">
          <video ref={vidRef} onEnded={handleVideoPlaybackEnd}>
            <source src={vidsrcURL} type="video/mp4" />
          </video>
          {showThumbnail()}
          {showPlayButton()}
          {/* {showDetails()} */}
          {playingVideo && <Close className="close-video" style={{ position: "absolute", height: "20px", width: "20px", color: "#434343bb", opacity: 0.75, zIndex: 99, padding: "15px", top: "5px", cursor: "pointer", 
          right: windowWidth > 1100 ? "70px" : "5px" }} onClick={(e) => pauseVideo(e)} />}
				</div>
				<div className="intro">
					<h1>Is your business ready to add a sales professional?</h1>
					<div className="intro-points">
						{searchParams.get("leader") ? <>
            <p>1. Take the 3-minute Sales Pulse Assessment</p>
            <p>2. View your results instantly</p>
            <p>3. Gain clarity about your growth potential</p>
            </> : <>
            <p>1. Take the 3-minute Sales Pulse Assessment</p>
            <p>2. Invite your team to take the same assessment</p>
            <p>3. View everyone's results on a team dashboard</p>
            <p>4. Gain clarity about your growth potential</p>
            </>}
					</div>

					{/* <div className="time-estimate">
						<Link to={assessmentLink()} className="btn-start">GET STARTED</Link>
						<img className="clock-icon" src={Clock} alt="" />{" "}
						<span>3 min</span>
					</div> */}

          <div className="completion">
            <Link to={assessmentLink()} className="btn-start" style={{ marginTop: searchParams.get("leader") ? "35px" : null }}>GET STARTED</Link>
            <div className='time-estimate' style={{ marginTop: searchParams.get("leader") ? "50px" : "15px" }}>
              <img className='clock-icon' src={Clock} alt="estimated time" /> <span>{searchParams.get("leader") ? "3 min" : "3 min"}</span>
            </div>
          </div>

				</div>
			</div>



      {/* <Video vidsrcURL={vidsrcURL} thumbnail={VideoThumbnail} playButton={PlayBtn} /> */}
      {/* <div className="video">
        <video ref={vidRef} onEnded={handleVideoPlaybackEnd}>
          <source
            src={vidsrcURL}
            type="video/mp4"
            />
        </video>
        {showThumbnail()}
        {showPlayButton()}
        {playingVideo && <Close className="close-video" style={{ position: "absolute", height: "20px", width: "20px", color: "#dddddddd", opacity: 0.75, zIndex: 99, padding: "15px", top: "15px", cursor: "pointer", 
        right: windowWidth > 1100 ? "60px" : "20px" }} onClick={(e) => pauseVideo(e)} />}
      </div>
      <div className="intro">
        <h1>Your church is unique. Your growth plan should be too.</h1>
        <h2>Discover where you are on the journey toward increased impact by unlocking your score in 4 crucial aspects of church growth!</h2> */}
        {/*<br />
        
        <h2>Which of the 9 opportunities should be your #1 priority?</h2>*/}

        {/*<div className="completion">
          <Link to={assessmentLink()} className="cta">Get Started</Link>
          {/* <Link to={howItWorksLink()} className="cta">Get Started</Link>
          <div className='time-estimate'>
            <img className='clock-icon' src={Clock} alt="" /> <span>5 min</span>
          </div>
        </div>*/}

        {/* <div className="completion">Estimated time for completion: 3 min</div> */}
        {/* <div className="logos-section">
          {windowWidth < 831 && <>
            <img className="logo-left" src={Logo} alt="Executive Leadership Solutions Logo" srcset="" />
            <div className="logo-separator"></div>
          </>}

          <img className="logo-right" src={liquidLogo} alt="Liquid Church Logo" srcset="" />
          
        </div> 
      </div>*/}
    </>
  );
}

export default Start;
