import { useState, useEffect, useRef, useContext } from "react";
import { useNavigate, useSearchParams, useParams, Link } from "react-router-dom";

import ProgressBar from '../Components/ProgressBar';
import AssessmentQuestionContext from '../Store/AssessmentQuestionContext';
import '../App.scss';
import useWindowDimensions from '../Hooks/useWindowDimensions';
import { Button } from "@mui/material";
import prevArrow from '../Assets/Images/prev-arrow.svg';
import nextArrow from '../Assets/Images/next-arrow.svg';
import nextArrowWhite from '../Assets/Images/next-arrow-white.svg';

import flagGif from '../Assets/Images/wavingFlag.gif';
import ProgressBarSingle from '../Components/ProgressBarSingle';


const ProgressTwo = props => {
  
  const assessCtx                         = useContext(AssessmentQuestionContext);
  const { id }                            = useParams();
  let navigate                            = useNavigate();
  const { windowWidth }                   = useWindowDimensions();
  const [searchParams, setSearchParams]   = useSearchParams();
  
  const [isLoading, setIsLoading]         = useState(true);
  const [nextArrowImg, setNextArrowImg]   = useState(nextArrow);

  useEffect(() => {
    localStorage.removeItem("backFromSecond");
    localStorage.removeItem("goToNextSectionSecond");
  }, [])

  searchParams.get("leader");

  const assessmentLink = () => {
    localStorage.setItem("goToNextSectionSecond", "true");
    if(![...searchParams]?.length) {
      return "/assessment";
    } else {
      return "/assessment?" + searchParams;
    }
  }
  
  const backToAssessmentNextSection = () => {
    localStorage.setItem("goToNextSectionSecond", "true");
    if(id) navigate("/assessment/" + id + '/review?' + searchParams, { replace: true });
    else navigate("/assessment?" + searchParams, { replace: true });
  }

  const prevPage = () => {
    // so we can use this to set question number to 14 in assessment 
    localStorage.setItem("backFromSecond", "true");
    if(id) navigate("/assessment/" + id + '/review?' + searchParams, { replace: true });
    else navigate("/assessment?" + searchParams, { replace: true });
  }


  return (
    <div className="container assessment halfway">
      {/* <div className="intro steps"> */}
        {/* <ProgressBar currentProgress={3} questionsPerSection={3} sections={assessCtx.sections} count={13}/> */}
      <ProgressBarSingle currentProgress={5.5} totalNumOfQuestions={13} />
      <div className="mid-page-content">
        <img src={flagGif} className="gif" alt="animated flag"></img>
        {windowWidth > 650 ? <>
          <p className="progress-one-txt" style={{ maxWidth: "38ch", marginBottom: "0" }}>Great job! We're halfway through already.</p>
          <p className="progress-one-txt" style={{ maxWidth: "38ch", marginTop: "0" }}>Let's switch gears and look at lead generation.</p> 
        </>
        : <p className="progress-one-txt" style={{ maxWidth: "38ch", marginBottom: "0" }}>Great job! We're halfway through already. Let's switch gears and look at lead generation.</p>}
      </div>
      <div className="completion">
        <Button className="back-btn" onClick={prevPage}><img className="prev-arrow" src={prevArrow} alt="Previous Page Button" /><text>&nbsp;&nbsp;PREV</text></Button>
        <Button onClick={() => backToAssessmentNextSection()} 
          onMouseEnter={() => setNextArrowImg(nextArrowWhite)}
          onMouseLeave={() => setNextArrowImg(nextArrow)}
          className="cta">NEXT&nbsp;&nbsp;<img className="next-arrow" src={nextArrowImg} alt="Next Page Button" /></Button>
      </div>
    </div>
  );
}
export default ProgressTwo;